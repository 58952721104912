<template>
  <div class="success">
    签署合同已经生成，请在浏览器打开界面，并下载文件查看。
  </div>
</template>
<script>
import { viewContract } from '@/api/sign'
export default {
  name: 'Callback',
  data() {
    return {
      insuranceNo: ''
    }
  },
  async created() {
    this.insuranceNo = this.$route.query.insuranceNo
    if (this.insuranceNo) {
      const res = await viewContract({ insuranceNo: this.insuranceNo })
      if (res.success && res.content) {
        window.location.href = res.content
        // window.open(res.content, '_blank')
      } else {
        this.$toast(res.respMag)
      }
    }
  },
  methods: {

  }
}
</script>
<style>
.success {
  font-size: 16px;
  margin: 20px;
}
</style>